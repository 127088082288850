.searchbar-container-home {
  position: absolute;
  top: 15rem;
  left: 10rem;
  z-index: 15;
  max-width: 100%;
}
.search-input-bar .form-row {
  display: flex;
  flex-direction: row;
}
.searchbar-container-home .header-title-1 {
  font-weight: bold;
  color: lightgray;
}
.searchbar-container-home .header-title-2 {
  font-weight: bold;
  color: white;
  font-size: 300%;
}

.search-options {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.search-options .option {
  padding: 20px 20px 3px 0px;
  font-weight: bolder;
  font-size: 130%;
  color: white;
  padding-right: 50px;
  cursor: pointer;
  border-bottom: 4px solid white;
  border-radius: 0.25rem;
}
.search-options .option i {
  color: white;
  font-size: 150%;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 25px;
}

.logo-input-container {
  display: flex;
  flex-direction: row;
}
.logo-input-container span {
  padding: 0.375rem 0.75rem;
  background-color: white;
  color: purple;
  font-weight: bold;
  padding-top: 10px;
  /* border-left: 2px solid gray; */
  cursor: pointer;
  margin-top: 5px;
}

.logo-input-container input {
  border-radius: 0px;
  border: 0px;
  margin-top: 5px;
}

.logo-input-container {
  background-color: white;
  padding: 5px;
}
.arrow-container {
  background-color: white;
  padding: 5px;
}

.logo-input-container.search-button {
  border: 5px solid white;
}
.logo-input-container.search-button span {
  border-radius: 0.5rem;
}

@media (max-width: 1100px) {
  .searchbar-container-home {
    left: 5rem;
  }
  .form-row {
    flex-wrap: nowrap;
  }

  .search-input-bar {
    margin-right: 30px;
  }
}

@media (max-width: 800px) {
  .form-row {
    flex-wrap: wrap;
    background-color: white;
    border-radius: 10px;
    margin: 10px 0px;
    max-width: 95%;
    margin: auto !important;
  }
  .search-input-bar {
    margin-right: 0px;
  }
  .searchbar-container-home {
    position: absolute;
    top: 8rem;
    left: 0rem;
    padding: 5px;
    align-items: center;
  }

  .searchbar-container-home .header-title-1 {
    font-size: 90%;
    text-align: center;
  }

  .searchbar-container-home .header-title-2 {
    color: white;
    font-size: 150%;
    text-align: center;
    margin-bottom: 0px;
  }
  .search-options {
    margin-left: 5px;
  }
  .search-options .option {
    font-size: 130%;
    padding: 10px 20px 3px 0px;
  }

  .search-input-bar .form-row {
    display: flex;
    flex-direction: column;
  }
  .logo-input-container {
    padding: 7px 0px;
    margin-bottom: 6px;
    max-width: 90%;
    margin-left: 1rem;
    border-radius: 0.5rem;
    border: 1px solid gainsboro;
  }
  .logo-input-container.select-type {
    margin-top: 10px;
  }
  .logo-input-container span {
    border-left: 0px;
    border-right: 0px !important;
  }

  .logo-input-container.mid-options {
    padding: 0px;
    background-color: transparent;
    border: none !important;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 8rem;
    padding: 12px;
    border: 2px solid rgb(255, 255, 255);
  }
  .logo-input-container.mid-options .freight {
    padding: 12px;
    border: 2px solid rgb(255, 255, 255);
  }
  .arrow-container {
    padding: 0px;
    position: absolute;
    right: 2rem;
    margin-top: 6rem;
  }
  .arrow-container.express {
    margin-top: 7rem;
  }
  .logo-input-container.search-button {
    background-color: blue;
    border: 0px;
  }
  .logo-input-container.search-button span {
    margin-left: 10rem;
  }
}

@media (max-width: 800px) {
  .logo-input-container {
    margin-left: 2rem;
  }
  .searchbar-container-home .header-title-1 {
    font-size: 120%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 150%;
  }
  .search-options {
    margin-left: 150px;
  }
  .search-options .option {
    font-size: 130%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 20rem;
  }
  .logo-input-container.search-button span {
    margin-left: 20rem;
  }
}
@media (max-width: 700px) {
  .logo-input-container {
    margin-left: 1.3rem;
  }
  .searchbar-container-home .header-title-1 {
    font-size: 100%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 150%;
  }
  .search-options {
    margin-left: 100px;
  }
  .search-options .option {
    font-size: 120%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 15rem;
  }
  .logo-input-container.search-button span {
    margin-left: 18rem;
  }
}
@media (max-width: 600px) {
  .logo-input-container {
    margin-left: 1.3rem;
  }
  .searchbar-container-home .header-title-1 {
    font-size: 110%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 150%;
  }
  .search-options {
    margin-left: 60px;
  }
  .search-options .option {
    font-size: 120%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 13rem;
  }
  .logo-input-container.search-button span {
    margin-left: 16rem;
  }
}
@media (max-width: 500px) {
  .searchbar-container-home {
    position: absolute;
    top: 8rem;
    left: 0;
    padding: 5px;
    align-items: center;
  }
  .logo-input-container {
    margin-left: 1.3rem;
  }
  .searchbar-container-home .header-title-1 {
    font-size: 100%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 150%;
  }
  .search-options {
    margin-left: 25px;
  }
  .search-options .option {
    font-size: 120%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 11rem;
  }
  .logo-input-container.search-button span {
    margin-left: 14rem;
  }
}
@media (max-width: 475px) {
  .logo-input-container.mid-options .door-to-door {
    margin-left: 10rem;
  }
  .logo-input-container.search-button span {
    margin-left: 13rem;
  }
}

@media (max-width: 450px) {
  .logo-input-container {
    margin-left: 1.2rem;
  }
  .searchbar-container-home .header-title-1 {
    font-size: 90%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 180%;
  }
  .search-options {
    margin-left: 20px;
  }
  .search-options .option {
    font-size: 110%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 9rem;
  }
  .logo-input-container.search-button span {
    margin-left: 12rem;
  }
}

@media (max-width: 425px) {
  .logo-input-container.mid-options .door-to-door {
    margin-left: 8rem;
  }
  .logo-input-container.search-button span {
    margin-left: 11rem;
  }
}
@media (max-width: 400px) {
  .searchbar-container-home .header-title-1 {
    font-size: 75%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 160%;
  }

  .search-options {
    margin-left: 10px;
  }

  .search-options .option {
    font-size: 100%;
  }

  .logo-input-container {
    margin-left: 1rem;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 7rem;
  }
  .logo-input-container.search-button span {
    margin-left: 10rem;
  }
}
@media (max-width: 376px) {
  .logo-input-container.mid-options .door-to-door {
    margin-left: 6.5rem;
  }
  .search-options .option {
    font-size: 100%;
  }
  .logo-input-container.search-button span {
    margin-left: 9rem;
  }
}
@media (max-width: 350px) {
  .searchbar-container-home .header-title-1 {
    font-size: 60%;
  }
  .searchbar-container-home .header-title-2 {
    font-size: 150%;
  }

  .search-options {
    margin-left: 5px;
  }

  .search-options .option {
    font-size: 100%;
  }
  .logo-input-container.mid-options .door-to-door {
    margin-left: 6rem;
  }
  .logo-input-container.search-button span {
    margin-left: 8rem;
  }
}

@media (max-width: 1000px) {
  .search-options {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
}
@media (max-width: 820px) {
  .logo-input-container .enter-name-span {
    margin-bottom: 5px;
  }
  .logo-input-container .enter-name-input {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 800px) {
  .logo-input-container.mid-options .door-to-door {
    margin-top: 0px;
    padding-top: 15px;
  }
  .logo-input-container.mid-options .door-to-door .d2d-text {
    margin-top: 5px;
  }
  .logo-input-container.mid-options .freight {
    margin-top: 0px;
    padding-top: 15px;
  }
  .logo-input-container.mid-options .freight .freight-text {
    margin-top: 5px;
  }
}

.below-searchbar-recommendation {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 10px 30px 10px 10px;
  margin-top: 50px;
  margin-left: 20px;
  background-color: white;
}

.below-searchbar-recommendation li {
  padding: 8px;
  cursor: pointer;
}

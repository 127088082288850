.make-payment-header {
  color: black;
  text-transform: none;
  font-size: 250%;
  text-align: center;
}

.make-payment-container {
  background-color: white;
  padding: 20px 0px;
}

@media (max-width: 600px) {
  .make-payment-header {
    font-size: 180%;
  }

  .nav-link.each-option {
    font-size: 70%;
    padding: 10px;
  }

  .make-payment-container {
    padding: 10px 0px !important;
  }
}

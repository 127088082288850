@media (min-width: 990px) {
  .order-tracking-modal-result {
    max-width: 40%;
  }
}
.order-tracking-bar span {
  font-size: 250%;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
}

.order-tracking-bar span div i {
  border: 1px solid gainsboro;
  border-radius: 10rem;
  padding: 5px;
  color: gainsboro;
}

.order-tracking-bar span p {
  font-size: 30%;
  display: flex;
  margin-top: 14px;
}

.icon-and-line-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-line {
  height: 50px;
  width: 3px;
  background-color: gainsboro;
  align-self: center;
}

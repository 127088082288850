.sticker-container {
  height: 650px;
  width: 1000px;
  background-color: white;
}

.flex-box {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: space-around;
  padding-left: 40px;
}
.flex-box-2 {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: center;
  padding-left: 40px;
}

.carton-no {
  font-weight: bold;
  font-size: 130px;
  padding: 100px;

  position: absolute;
  margin-top: 20px;
  left: 20px;
}

.lot-no {
  padding: 10px;
  max-width: 160px;
  font-size: 20px;
  margin-top: -20px;
  margin-left: 500px;
}

.barcode-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.product-information {
  display: flex;
  justify-content: center;
}

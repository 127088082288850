.what-we-offer1 {
  height: 700px;
  background-image: url("./assets/Slider-Image-2.png");
  box-shadow: 0px 18px 76px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 99;
}

.support {
  height: 300px;
  background-image: url("./assets/Slider-Image-4.png");
  background-repeat: no-repeat;
  background-position: center;
}

.safe-and-secure {
  height: 300px;
  background-image: url("./assets/Slider-Image-5.png");
  background-repeat: no-repeat;
  background-position: center;
}
.fast-delivery {
  height: 300px;
  background-image: url("./assets/Slider-Image-3.png");
  background-repeat: no-repeat;
  background-position: center;
}

.service-background {
  background-image: url("./assets/Service.png") !important;
}

img {
  transform: none !important;
  width: 100%;
}

.banner-background-image {
  padding: 0 !important;
  margin: 0;
  min-height: 100vh;
  background: url("banner_slider_4.jpg");
  background-size: cover;
  animation: animateBg 276s linear infinite;
  box-shadow: inset 0 0 0 2000px rgba(6, 6, 158, 0.288);
}

.pulse-earth-image {
  position: absolute;
  top: 8%;
  right: -10%;
  transform: translate(-50%, -50%);
  background: url("earth.png");
  background-size: cover;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  box-shadow: inset 0 0 40px #120e2e;
  animation: animateEarth 36s linear infinite;
  z-index: 10;
}
.pulse-background {
  position: absolute;
  top: 8%;
  right: -10%;
  transform: translate(-50%, -50%);
  background: rgb(3 8 63);
  background-size: cover;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  z-index: 5;
  box-shadow: 4px 10px 90px -2px #212124;
}
@keyframes animateEarth {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 719px 0;
  }
}
@keyframes animateBg {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 3952px 0;
  }
}

@media (max-width: 600px) {
  .pulse-background {
    top: 5%;
  }
  .pulse-earth-image {
    top: 5%;
  }
}
